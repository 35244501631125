<template>
  <div class="edit-table">
    <vxe-table show-overflow="" ref="fineTable" :data="subjectList">
      <vxe-table-column
        min-width="100"
        field="companyAwardCode"
        title="奖励预算编码"
      ></vxe-table-column>
      <vxe-table-column
        min-width="100"
        field="year"
        title="年度"
      ></vxe-table-column>
      <vxe-table-column
        min-width="100"
        field="companyName"
        title="公司主体"
      ></vxe-table-column>
      <vxe-table-column
        min-width="100"
        field="createName"
        title="调整人"
      ></vxe-table-column>
      <vxe-table-column
        min-width="100"
        field="createDateAll"
        title="调整时间"
      ></vxe-table-column>
      <vxe-table-column
        min-width="100"
        field="adjustAmount"
        title="调整金额"
      ></vxe-table-column>
      <vxe-table-column
        min-width="100"
        field="residueAmount"
        title="余额"
      ></vxe-table-column>
      <vxe-table-column
        min-width="100"
        field="remarks"
        title="备注"
      ></vxe-table-column>
      <vxe-table-column min-width="200" field="attachmentList" title="附件">
        <template v-slot="{ row }">
          <div class="file-list-detail">
            <div
              v-for="(item, index) in row.attachmentList"
              class="file-list"
              :key="index"
            >
              <i class="el-icon-tickets"></i>
              <div class="file-name">{{ item.name }}</div>
              <a
                :href="`/upload/downloadController/download?objectName=${item.objectName}`"
                :download="item.name"
                ><i class="el-icon-download"></i
              ></a>
            </div>
          </div>
        </template>
      </vxe-table-column>
    </vxe-table>
  </div>
</template>

<script>
import request from '@/found/utils/request';
import { log } from 'mathjs';
import { download } from '@/found/utils/index';

export default {
  name: 'BudgetSubject',
  props: {
    value: Array,
    disabled: Boolean,
  },
  watch: {
    value() {
      console.log(this.value);
      this.subjectList = this.value;
      this.subjectList = (this.subjectList || []).map((v) => {
        const obj = v;
        obj.attachmentList = (obj.attachmentList || []).map((m) => {
          const obj1 = m;
          obj1.name = obj1.fileName;
          obj1.url = obj1.url ? obj1.url : obj1.filePath;
          obj1.objectName = obj1.objectName
            || ((obj1.url ? obj1.url : obj1.filePath || obj1.fileUrl) || '')
              .split('/')
              .splice(-3)
              .join('/');
          return obj1;
        });
        return obj;
      });
      console.log(this.subjectList);
    },
  },
  data() {
    return {
      subjectList: [], // 当前关联费用科目数据
    };
  },
  methods: {
    // 文件下载
    downloadfile(files) {
      download(`${files.url}`, files.name);
    },
  },
};
</script>

<style lang="less" scoped>
.edit-table {
  padding-left: 50px;
}
.file-list {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 18px;
  .file-name {
    flex: 1;
    padding: 0 10px;
    width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &:hover {
      background: rgba(0, 0, 0, 0.1);
    }
  }
  > i {
    padding: 0 5px;
  }
}
.file-list-detail {
  padding: 10px 0;
}
</style>
