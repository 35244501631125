<template>
  <div class="edit-table">
    <vxe-table show-overflow="" ref="fineTable" :data="subjectList">
      <vxe-table-column min-width="100" field="dealerName" title="受益经销商"></vxe-table-column>
      <vxe-table-column min-width="100" field="applyAmount" title="申请金额"></vxe-table-column>
      <vxe-table-column min-width="100" field="createDateAll" title="申请时间"></vxe-table-column>
      <vxe-table-column min-width="100" field="auditStatusName" title="审核状态"></vxe-table-column>
      <!-- <vxe-table-column min-width="100" field="ext1" title="余额"></vxe-table-column> -->
    </vxe-table>
  </div>
</template>

<script>
import request from '@/found/utils/request';
import { log } from 'mathjs';

export default {
  name: 'BudgetSubject',
  props: {
    value: Array,
    disabled: Boolean,
  },
  watch: {
    value() {
      console.log(this.value);
      this.subjectList = this.value;
    },
  },
  data() {
    return {
      subjectList: [], // 当前关联费用科目数据
    };
  },
  methods: {

  },
};
</script>

<style lang="less" scoped>
.edit-table {
  padding-left: 50px;

}
</style>
