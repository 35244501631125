<script>
import request from '@/found/utils/request';
import Storage from '@/found/utils/storage';
import Form, { formCreate } from '@/found/components/form';
import TableSelect from '../components/tableSelect.vue';

formCreate.component('TableSelect', TableSelect);
export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      formFunctionCode: 'dms_incentive_budget_form',
    };
  },

  methods: {
    // 设置rule
    setRule(v) {
      const item = v;
      if (item.field === 'companyName') {
        const paramsObj = {
          functionCode: 'company_body_table',
          data: [],
          selectionList: [],
          idKey: 'companyCode',
          noReset: true,
          paramData: { enableStatus: '009' },
        };
        item.props = {
          ...item.props,
          paramsObj,
          placeholder: '公司主体',
        };
      }
      if (item.field === 'totalAmount') {
        item.props = {
          ...item.props,
          min: 0,
          precision: 2,
        };
      }
      return item;
    },
    // 表单渲染完成后调用
    async formComplete() {
      const company = this.getRule('companyName');
      company.on.change = (val) => {
        this.setValue({
          companyCode: val.companyCode,
          companyName: val.companyName,
        });
      };
      if (this.formConfig.code === 'edit' || this.formConfig.code === 'view') {
        this.setValue({ ...this.formConfig.row });
      }
    },

    // 表单提交
    submit() {
      const formData = this.getFormData();
      if (formData) {
        const url = '/dms/dmsawardcompanybudget/save';
        const params = { ...formData };
        request.post(url, params).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '操作成功',
            });
            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
      }
    },
  },
};
</script>
