import request from '@/found/utils/request';
import Form, { formCreate } from '@/found/components/form';
import DetailTable from '../components/budget_subject_table.vue';
import AmoutTable from '../components/amout_table.vue';

formCreate.component('DetailTable', DetailTable);
formCreate.component('AmoutTable', AmoutTable);

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      formParentCode: 'CRM20230307000000003',
      formFunctionCode: 'dms_incentive_budget_detail_form',
      approvalStateList: [],
    };
  },
  created() {
    request
      .post('/mdm/mdmdictdata/list', {
        dictTypeCode: 'dms_approval_state',
      })
      .then((res) => {
        this.approvalStateList = res.result ? res.result : [];
      });
  },
  methods: {
    // 设置rule
    setRule(item) {
      const v = item;
      if (v.field === 'dealerBudgetRespVos') {
        v.props = {
          ...v.props,
        };
        v.value = [];
      } else if (v.field === 'totalAmount') {
        v.props = {
          ...v.props,
          min: 0,
          precision: 2,
        };
      } else if (v.field === 'adjustDetails') {
        v.props = {
          ...v.props,
        };
        v.value = [];
      }
      return v;
    },
    // 表单渲染完成后回调
    formComplete() {
      if (this.formConfig.code === 'edit' || this.formConfig.code === 'view') {
        request
          .post('/dms/dmsawardcompanybudget/query', {
            id: this.formConfig.row ? this.formConfig.row.id : '',
          })
          .then((res) => {
            if (res.success) {
              const data = res.result;
              data.dealerBudgetRespVos = data.dealerBudgetRespVos.map((v) => {
                const obj = v;
                obj.auditStatusName = (this.approvalStateList.find((m) => m.dictCode === obj.auditStatus) || {}).dictValue || '';
                return obj;
              });
              data.attachmentList = data.attachmentList ? data.attachmentList : [];
              this.setValue(data);
            }
          });
      }
    },
    // 表单提交
    submit() {
      const formData = this.getFormData();
      if (formData) {
        delete formData.dealerBudgetRespVos;
        delete formData.title1;
        delete formData.title2;
        delete formData.title3;
        delete formData.adjustDetails;
        formData.id = this.formConfig.row.id || '';
        formData.companyAwardCode = this.formConfig.row.companyAwardCode || '';
        const url = '/dms/dmsawardcompanybudget/update';
        const params = { ...formData };
        request.post(url, params).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '操作成功',
            });
            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
      }
    },
  },
};
