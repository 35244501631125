<script>
import TablePage from '@/found/components/table_page';
import request from '@/found/utils/request';
import Form from './form';
import detailForm from './detail';

export default {
  extends: TablePage,
  components: {
    Form,
    detailForm,
  },
  data() {
    return {
      formConfig: {},
      searchFormData: {},
      modalConfig: {
        title: '新增',
        visible: false,
        type: 'Modal',
      },
    };
  },
  mounted() {
    this.getConfigList('dms_incentive_budget_table');
  },
  methods: {
    // 行按钮隐藏
    clickVisible({ val, row }) {
      if (val.buttonCode === 'edit') {
        if (row.auditStatus === '2' || row.auditStatus === '3') {
          return false;
        }
        console.log({ ...row });
        return true;
      }
      return true;
    },
    modalClick({ val, row }) {
      console.log(val.code);
      if (val.code === 'add') {
        this.formName = 'Form';
        this.modalConfig.title = '新增';
        this.modalConfig.width = '40%';
        this.formConfig.row = row;
        this.formConfig.code = val.code;
        this.openModal();
      } else if (val.code === 'view' || val.code === 'edit') {
        this.formName = 'detailForm';
        this.modalConfig.title = '查看明细';
        this.modalConfig.width = '1300px';
        this.formConfig.row = row;
        this.formConfig.code = val.code;
        this.openModal();
      }
    },
  },
};
</script>
