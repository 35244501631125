var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "edit-table" },
    [
      _c(
        "vxe-table",
        {
          ref: "fineTable",
          attrs: { "show-overflow": "", data: _vm.subjectList },
        },
        [
          _c("vxe-table-column", {
            attrs: {
              "min-width": "100",
              field: "companyAwardCode",
              title: "奖励预算编码",
            },
          }),
          _c("vxe-table-column", {
            attrs: { "min-width": "100", field: "year", title: "年度" },
          }),
          _c("vxe-table-column", {
            attrs: {
              "min-width": "100",
              field: "companyName",
              title: "公司主体",
            },
          }),
          _c("vxe-table-column", {
            attrs: { "min-width": "100", field: "createName", title: "调整人" },
          }),
          _c("vxe-table-column", {
            attrs: {
              "min-width": "100",
              field: "createDateAll",
              title: "调整时间",
            },
          }),
          _c("vxe-table-column", {
            attrs: {
              "min-width": "100",
              field: "adjustAmount",
              title: "调整金额",
            },
          }),
          _c("vxe-table-column", {
            attrs: {
              "min-width": "100",
              field: "residueAmount",
              title: "余额",
            },
          }),
          _c("vxe-table-column", {
            attrs: { "min-width": "100", field: "remarks", title: "备注" },
          }),
          _c("vxe-table-column", {
            attrs: {
              "min-width": "200",
              field: "attachmentList",
              title: "附件",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "div",
                      { staticClass: "file-list-detail" },
                      _vm._l(row.attachmentList, function (item, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "file-list" },
                          [
                            _c("i", { staticClass: "el-icon-tickets" }),
                            _c("div", { staticClass: "file-name" }, [
                              _vm._v(_vm._s(item.name)),
                            ]),
                            _c(
                              "a",
                              {
                                attrs: {
                                  href:
                                    "/upload/downloadController/download?objectName=" +
                                    item.objectName,
                                  download: item.name,
                                },
                              },
                              [_c("i", { staticClass: "el-icon-download" })]
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }