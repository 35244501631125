var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "edit-table" },
    [
      _c(
        "vxe-table",
        {
          ref: "fineTable",
          attrs: { "show-overflow": "", data: _vm.subjectList },
        },
        [
          _c("vxe-table-column", {
            attrs: {
              "min-width": "100",
              field: "dealerName",
              title: "受益经销商",
            },
          }),
          _c("vxe-table-column", {
            attrs: {
              "min-width": "100",
              field: "applyAmount",
              title: "申请金额",
            },
          }),
          _c("vxe-table-column", {
            attrs: {
              "min-width": "100",
              field: "createDateAll",
              title: "申请时间",
            },
          }),
          _c("vxe-table-column", {
            attrs: {
              "min-width": "100",
              field: "auditStatusName",
              title: "审核状态",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }